body {
  margin: 0;
  padding: 0;
}

#header {
  width: 1146px;
  height: 222px;
  margin: 0 auto;
  padding: 0 0 0 5px;
  position: relative;
  top: 44px;
  z-index: 0;
  background: url(//static.leagueoflegends.co.kr/common/img/common/bg/menubg.png) no-repeat center top;
}