.app {
  margin: 0 1rem 100px;
  @font-face {
    font-family: "NanumGothic";
    font-style: normal;
    font-weight: normal;
    src: url(//static.leagueoflegends.co.kr/common/fonts/NanumGothic.eot);
    src:
      url(//static.leagueoflegends.co.kr/common/fonts/NanumGothic.eot?#iefix) format("embedded-opentype"),
      url(//static.leagueoflegends.co.kr/common/fonts/NanumGothic.woff) format("woff");
  }
  @font-face {
    font-family: "NanumGothicBold";
    font-style: normal;
    font-weight: normal;
    src: url(//static.leagueoflegends.co.kr/common/fonts/NanumGothicBold.eot);
    src:
      url(//static.leagueoflegends.co.kr/common/fonts/NanumGothicBold.eot?#iefix) format("embedded-opentype"),
      url(//static.leagueoflegends.co.kr/common/fonts/NanumGothicBold.woff) format("woff");
  }
  @font-face {
    font-family: "NanumMyeongjo";
    font-style: normal;
    font-weight: normal;
    src: url(//static.leagueoflegends.co.kr/common/fonts/NanumMyeongjo.eot);
    src:
      url(//static.leagueoflegends.co.kr/common/fonts/NanumMyeongjo.eot?#iefix) format("embedded-opentype"),
      url(//static.leagueoflegends.co.kr/common/fonts/NanumMyeongjo.woff) format("woff");
  }
  @font-face {
    font-family: "NanumMyeongjoBold";
    font-style: normal;
    font-weight: normal;
    src: url(//static.leagueoflegends.co.kr/common/fonts/NanumMyeongjoBold.eot);
    src:
      url(//static.leagueoflegends.co.kr/common/fonts/NanumMyeongjoBold.eot?#iefix) format("embedded-opentype"),
      url(//static.leagueoflegends.co.kr/common/fonts/NanumMyeongjoBold.woff) format("woff");
  }
}

.answers {
  margin: 0 2rem;
  padding: 0;

  .question {
    margin: 1em 0 2em 1em;
  }

  .label {
    margin-bottom: 0.5em;
  }

  .input {
    margin: 1rem 0;

    ul {
      padding: 0 1rem;
    }

    li {
      margin: 1rem 0;
    }

    input[type="text"],
    input[type="number"],
    select {
      width: 15em;
      padding: 0.5rem;
      border: 0;
      border-bottom: 1px solid #ddd;
      &::placeholder {
        color: #d7d7d7;
      }
    }

    textarea {
      width: 100%;
      height: 5rem;
      padding: 0.5rem;
      border: 1px solid #ddd;
    }
  }

  .flex {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
}

.confirm {
  text-align: center;
  font-weight: bold;

  .message {
    margin: 0 0 1em 0;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem 0;

  button {
    margin-top: 1rem;
    background: #000;
    padding: 1rem 4rem;
    color: #fff;
    border: 0;
    border-radius: 0.2rem;
  }

  button.gray {
    background: #ddd;
    color: #000;
  }
}
