.page {
  max-width: 1000px;
  margin: 0 auto 0;
}

.page-title {
  padding: 0 0 10px 0;
  font-size: 30px;
  color: #000;
  letter-spacing: -1px;

  &__description {
    color: #555;
    font-size: 0.9rem;
    ul {
      margin: 0 20px;
      padding: 0;
    }
    li {
      margin: 0.5rem 0;
    }
    margin-bottom: 30px;
  }
}

.form-container {
  margin: 40px 0;

  .tips {
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
    width: 90%;
    padding: 0 30px;
    margin: 0 auto;
    font-size: 12px;
    color: #555;
    li {
      margin: 1em 0 1em 0;
    }
  }
}

.waiting {
  .block {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: fixed;
    background-color: rgba(22, 22, 22, 0.3);
  }

  .none {
    display: none;
  }
}
