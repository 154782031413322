.finish-page {
  div {
    text-align: center !important;
  }

  .welcome {
    font-weight: bold;
    font-size: 15px;
    line-height: 2.0em;
    margin-bottom: 4em;
  }
}